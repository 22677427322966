import React, { Fragment } from "react";
import Spinner from "../layout/Spinner";

import { Link } from "react-router-dom";

const Member = ({ member }) => {
  return (
    <Fragment>
      <Link to="/members" className="btn btn-light">
        الرجوع للبحث
      </Link>
      {/* Hireable:{' '}
			{hireable ? (
				<i className="fas fa-check text-success" />
			) : (
				<i className="fas fa-times-circle text-danger" />
			)} */}
      <div className="card text-right">
        <div className="grid-2 ">
          <div>
            {member.name && (
              <Fragment>
                <p>
                  الاستاذ <u> {member.name} </u> هو احد اعضاء هيئة التدريس
                  بجامعة الزاوية كلية <u> {member.faculty} </u>
                  قسم <u> {member.department} </u>
                  منذ سنة <u> {member.tss.datedegree} </u>
                </p>

                <p>
                  الجنسية :<u> {member.nationality}</u>
                </p>
                <p>
                  الجنس : <u>{member.sex}</u>
                </p>

                <p>
                  {" "}
                  المؤهل العلمي :{" "}
                  <u>{member.tss && member.tss.qualification}</u>
                </p>
                <p>
                  التخصص العام : <u>{member.tss && member.tss.gspecialty}</u>
                </p>
                <p>
                  التخصص الدقيق :{" "}
                  <u>{member.tss && member.tss.specialization}</u>
                </p>
                <p>
                  {" "}
                  الدرجة العلمية : <u>{member.tss && member.tss.nowdegree}</u>
                </p>
              </Fragment>
            )}
          </div>
          <div className="all-center">
            <img
              src={`https://portal.zu.edu.ly${member.image}`}
              className="round-img"
              alt=""
              style={{ width: "150px" }}
            />
            <h6>{member.name}</h6>
          </div>
        </div>
        {member.resume && member.resume.courses !== "" ? (
          <div className="m-3">
            <h4> :المراكز التي شغلها </h4>
            <p dangerouslySetInnerHTML={{ __html: member.resume.courses }}></p>
          </div>
        ) : null}
      </div>
      <div className="card text-center">
        {member.resume && (
          <a
            href={`https://portal.zu.edu.ly${member.resume.file}`}
            style={{ fontSize: "3rem" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            CV
          </a>
        )}
        {member.researchgate && member.researchgate.length > 0 ? (
          <a
            target="_blank"
            href={member.researchgate}
            className=""
            rel="noopener noreferrer"
          >
            <i className="fab fa-researchgate fa-3x"></i>{" "}
          </a>
        ) : null}

        {member.linkedin && member.linkedin.length > 0 ? (
          <a
            target="_blank"
            href={member.linkedin}
            className=""
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin fa-3x"></i>{" "}
          </a>
        ) : null}

        {member.gscholar && member.gscholar.length > 0 ? (
          <a
            target="_blank"
            href={member.gscholar}
            style={{ fontSize: "3rem" }}
            rel="noopener noreferrer"
          >
            GS{" "}
          </a>
        ) : null}

        {member.email && member.email.length > 0 ? (
          <a
            target="_blank"
            href={`mailto: ${member.email}`}
            rel="noopener noreferrer"
          >
            <i className="fas fa-envelope fa-3x"></i>
          </a>
        ) : null}
      </div>
      <div className="card ">
        <h4 className="text-center"> المواد </h4>

        <small>{member.name}</small>
        <hr />
        {member.subjects.map(subject => (
          <div className="col s12 " style={{ direction: "rtl" }}>
            <div className="card horizontal">
              <div className="card-image">
                <img
                  src={`https://portal.zu.edu.ly${subject.image}`}
                  alt="courses"
                />
              </div>
              <div className="card-stacked">
                <div className="card-content">
                  <h5>{subject.subjectname}</h5>
                  <p>{subject.about}</p>
                </div>
                <div className="card-action text-center">
                  <Link to={`/subject/${subject._id}`}>التفاصيل</Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="card ">
        <h4 className="text-center">الابحاث المنشورة</h4>
        <small>{member.name}</small>
        <hr />
        {member.research.map(res => (
          <div className="col s12 " style={{ direction: "rtl" }}>
            <div className="card ">
              <div className="card-content ">
                <span className="card-title">{res.filename}</span>
                <small>{res.writer}</small>
                <p>{res.discription}</p>
              </div>
              <div className="card-action">
                <div className="text-left">
                  {res && res.link ? (
                    <a
                      href={res.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      الانتقال لرابط
                      {"  "}
                      <i className="fas fa-angle-double-left"></i>
                    </a>
                  ) : (
                    <a
                      href={`https://portal.zu.edu.ly${res.file}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      تحميل الملف
                      {"  "}
                      <i className="fas fa-angle-double-down"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default Member;

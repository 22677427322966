import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { clearSubject } from "../../actions/Subject.actions";

const StudentItem = ({
  clearSubject,
  subject: { _id, subjectname, image, about, googleclass },
}) => {
  return (
    <div className="col s12 " style={{ direction: "rtl" }}>
      <div className="card horizontal">
        <div className="card-image">
          <img src={`https://portal.zu.edu.ly${image}`} alt="courses" />
        </div>
        <div className="card-stacked">
          <div className="card-content">
            <h5>{subjectname}</h5>
            <h5>{googleclass}</h5>
            <p>{about}</p>
          </div>
          <div className="card-action text-center">
            <Link to={`/subject/${_id}`} onClick={() => clearSubject()}>
              التفاصيل
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

StudentItem.propTypes = {
  subject: PropTypes.object.isRequired,
};

export default connect(null, { clearSubject })(StudentItem);

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// Members
export const GET_MEMBERS = "GET_MEMBERS";
export const GET_MEMBER = "GET_MEMBER";
export const MEMBER_ERROR = "MEMBER_ERROR";
export const SEARCH_MEMBERS = "SEARCH_MEMBERS";
export const FILTER_MEMBERS = "FILTER_MEMBERS";
export const CLEAR_MEMBER = "CLEAR_MEMBER";

// Subjects
export const GET_SUBJECTS = "GET_SUBJECTS";
export const GET_SUBJECT = "GET_SUBJECT";
export const SUBJECT_ERROR = "SUBJECT_ERROR";
export const SEARCH_SUBJECTS = "SEARCH_SUBJECTS";
export const CLEAR_SUBJECT = "CLEAR_SUBJECT";
export const FILTER_SUBJECTS = "FILTER_SUBJECTS";

export const CLEAR_FILTER = "CLEAR_FILTER";

export const GET_FACULTIES = "GET_FACULTIES";
export const GET_DEPARTMENNTS = "GET_DEPARTMENNTS";
export const DEPARTMENT_ERROR = "DEPARTMENT_ERROR";

// STUDENTS
export const GET_STUDENTS = "GET_STUDENTS";
export const GET_STUDENT = "GET_STUDENT";
export const STUDENT_ERROR = "STUDENT_ERROR";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const ADD_STUDENT = "ADD_STUDENT";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const FILTER_STUDENT = "FILTER_STUDENT";

import axios from "axios";
import {
  GET_SUBJECTS,
  GET_SUBJECT,
  SUBJECT_ERROR,
  SEARCH_SUBJECTS,
  CLEAR_SUBJECT,
  FILTER_SUBJECTS,
  CLEAR_FILTER,
} from "./types";

// Get Students
export const getSubjects = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };
    const res = await axios.get(
      "https://portal.zu.edu.ly/api/subjects/all",
      config
    );

    dispatch({
      type: GET_SUBJECTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SUBJECT_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// search Subject
export const searchSubjets = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  };
  try {
    const res = await axios.post(
      "https://portal.zu.edu.ly/api/subjects",
      formData,
      config
    );
    console.log(res.data);
    dispatch({
      type: SEARCH_SUBJECTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SUBJECT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// filter student
export const filterSubject = (text) => (dispatch) => {
  dispatch({ type: FILTER_SUBJECTS, payload: text });
};

//clear filter
export const clearFilter = () => (dispatch) => {
  dispatch({ type: CLEAR_FILTER });
};
//clear filter
export const clearSubject = () => (dispatch) => {
  dispatch({ type: CLEAR_SUBJECT });
};

// Get one Student
export const getSubject = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `https://portal.zu.edu.ly/api/facultymembers/subject/${id}`
    );
    dispatch({
      type: GET_SUBJECT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SUBJECT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

import axios from "axios";
import { GET_FACULTIES, GET_DEPARTMENNTS, DEPARTMENT_ERROR } from "./types";

// Get Students
export const getFaculties = () => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
      }
    };
    const res = await axios.get("https://zu.edu.ly/api/faculty", config);

    dispatch({
      type: GET_FACULTIES,
      payload: res.data
    });
  } catch (err) {
    console.log(err.response);
    dispatch({
      type: DEPARTMENT_ERROR,
      payload: { msg: err.response, status: err.response }
    });
  }
};

// filter student
export const getDepartment = name => dispatch => {
  dispatch({ type: GET_DEPARTMENNTS, payload: name });
};

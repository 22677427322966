import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";

import { getSubjects } from "../../actions/Subject.actions";

import Filter from "./SubjectsFilter.components";
import SubjectsItem from "./SubjectsItem.components";
import SearchModal from "./searchModal";
import M from "materialize-css/dist/js/materialize.min.js";

const Subjects = ({
  getSubjects,
  subjects: { subjects, filtered, loading },
}) => {
  useEffect(() => {
    var elems2 = document.querySelectorAll(".modal");
    M.Modal.init(elems2, {
      opacity: 0.7,
      inDuration: 300,
    });
    var elems = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems, { position: "top", margin: 9 });
    M.AutoInit();

    getSubjects();
  }, [loading]);

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div style={{ direction: "rtl", marginTop: "50px" }}>
        <Filter />
        {filtered !== null && filtered.length !== 0
          ? filtered.map((subject) => (
              <SubjectsItem key={subject._id} subject={subject} />
            ))
          : subjects.map((subject) => (
              <SubjectsItem key={subject._id} subject={subject} />
            ))}
      </div>

      <div className="fixed-action-btn">
        <a
          href="#search-modal"
          data-position="bottom"
          className="btn-floating btn-large  blue lighten-1 pulse modal-trigger tooltipped"
          data-tooltip="بحث متقدم"
        >
          <i className="large material-icons">search</i>
        </a>
      </div>
      <SearchModal />
    </Fragment>
  );
};

Subjects.propTypes = {
  getSubjects: PropTypes.func.isRequired,
  subjects: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  subjects: state.subjects,
});

export default connect(mapStateToProps, { getSubjects })(Subjects);

import React, { useEffect, Fragment } from "react";
import Spinner from "../layout/Spinner";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getMember } from "../../actions/Member.actions";
import Memberi from "../members/Member.component";
import Navbar from "../layout/Navbar";

const Member = ({ match, getMember, members: { member, loading } }) => {
  useEffect(() => {
    getMember(match.params.id);
  }, [loading]);
  return !member ? (
    <Spinner />
  ) : (
    <Fragment>
      <Navbar
        title="عضؤ هيئة التدريس بالبوابة الالكتروني  | قسم الموقع الالكتروني"
        icon="fas fa-user"
      />
      <div className="container">
        <Memberi member={member} />
      </div>
    </Fragment>
  );
};

Member.propTypes = {
  getMember: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  members: state.members,
});

export default connect(mapStateToProps, { getMember })(Member);

import { combineReducers } from "redux";
import alert from "./alert";
import members from "./MemberReducer";
import subjects from "./SubjectReducer";
import faculties from "./FacultyReducer";

export default combineReducers({
  alert,
  members,
  subjects,
  faculties
});

import {
  GET_FACULTIES,
  GET_DEPARTMENNTS,
  DEPARTMENT_ERROR
} from "../actions/types";

const initialState = {
  faculies: [],
  faculy: null,
  loading: true,
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FACULTIES:
      return {
        ...state,
        faculies: payload,
        loading: false
      };

    case GET_DEPARTMENNTS:
      return {
        ...state,
        faculy: state.faculies.filter(faculy =>
          faculy.facultyname.match(payload)
        )
      };
    case DEPARTMENT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { searchSubjets } from "../../actions/Subject.actions";
import { getFaculties, getDepartment } from "../../actions/faculty.action";
import M from "materialize-css/dist/js/materialize.min.js";
import Spinner from "../layout/Spinner";
import Select from "react-select";

const SearchSubjectModal = ({
  searchSubjets,
  getDepartment,
  getFaculties,
  faculties: { faculies, faculy, loading },
}) => {
  useEffect(() => {
    var elems2 = document.querySelectorAll(".modal");
    M.Modal.init(elems2, {
      opacity: 0.7,
      inDuration: 300,
    });
    var elems = document.querySelectorAll("select");
    M.FormSelect.init(elems, {});

    getFaculties();
  }, [loading]);
  const [Subject, setSubject] = useState({
    faculty: "",
    department: "",
    year: "",
  });

  let options = [];

  faculy &&
    faculy.map((fac) =>
      fac.departments.map((department) =>
        options.push({
          value: department.departmentname,
          label: department.departmentname,
        })
      )
    );
  const onChange = (e) => {
    setSubject({ ...Subject, [e.target.name]: e.target.value });
  };
  const onChangeFaculty = (e) => {
    setSubject({ ...Subject, [e.target.name]: e.target.value });
    console.log(e.target.value);
    getDepartment(e.target.value);
  };
  const onChangeSelect = (selected) => {
    setSubject({ ...Subject, department: selected.value });
  };

  const { faculty, department, year } = Subject;

  const onSubmit = () => {
    if (faculty === "") {
      M.toast({ html: "اختر الكلية من فضلك" });
    } else {
      searchSubjets({ faculty, department, year });

      M.toast({ html: "سيتم جلب مواد البحث" });

      // Clear Fields
      setSubject({
        faculty: "",
        department: "",
        year: "",
      });
    }
  };
  return loading ? (
    <Spinner />
  ) : (
    <div
      id="search-modal"
      className="modal"
      style={{ direction: "rtl", padding: "30px 0" }}
    >
      <div className="modal-content" style={{ margin: "20px 0" }}>
        <h4 className="center" style={{ margin: "20px 0" }}>
          بحث متقدم
        </h4>
        <br />

        <div
          className="input-field col s12 text-center"
          style={{ paddingBottom: "30px" }}
        >
          <select name="faculty" value={faculty} onChange={onChangeFaculty}>
            <option value="" disabled selected>
              {"        "}
              اختر الكلية
            </option>
            {faculies.map((fac) => (
              <option key={fac._id} value={fac.facultyname}>
                {"        "}
                {fac.facultyname}{" "}
              </option>
            ))}
          </select>
        </div>

        <div
          className="input-field col s12 text-center"
          style={{ paddingBottom: "30px" }}
        >
          <select name="year" value={year} onChange={onChange}>
            <option value="" disabled selected>
              {"        "}
              اختر السنة الدراسية
            </option>
            <option value="السنة الاولى">
              {"        "}
              السنة الاولى
            </option>
            <option value="السنة التانية">
              {"        "}
              السنة التانية
            </option>
            <option value="السنة التالتة">
              {"        "}
              السنة التالتة
            </option>
            <option value="السنة الرابعة">
              {"        "}
              السنة الرابعة
            </option>
          </select>
        </div>
        <label>القسم</label>
        <Select
          options={options}
          name="department"
          noOptionsMessage={() => "اختر الكلية من فضلك"}
          placeholder={<div>اختر القسم</div>}
          onChange={onChangeSelect}
        />
      </div>
      <div className="modal-footer" style={{ paddingTop: "30px" }}>
        <a
          href="#!"
          onClick={onSubmit}
          className="modal-close waves-effect blue waves-light btn"
        >
          بحث
        </a>
      </div>
    </div>
  );
};

SearchSubjectModal.propTypes = {
  getDepartment: PropTypes.func.isRequired,
  getFaculties: PropTypes.func.isRequired,
  searchSubjets: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  faculties: state.faculties,
});
export default connect(mapStateToProps, {
  getDepartment,
  getFaculties,
  searchSubjets,
})(SearchSubjectModal);

import React, { Fragment, useEffect } from "react";

import M from "materialize-css/dist/js/materialize.min.js";
import AddStudentModel from "../models/AddStudentModal";
import Navbar from "../layout/Navbar";

const Students = () => {
  useEffect(() => {
    var elems2 = document.querySelectorAll(".modal");
    M.Modal.init(elems2, {
      opacity: 0.7,
      inDuration: 300,
    });
  });
  return (
    <Fragment>
      <Navbar
        title="نموذج تسجيل طالب | مركز المعلومات و التوثيق "
        icon="fas fa-user-graduate"
      />
      {/* // <!-- Section: Comments --> */}
      <AddStudentModel />

      {/* <!-- Footer --> */}
      <footer className="page-footer blue darken-1 ">
        <div className="footer-copyright blue lighten-1">
          <div className="container">
            جامعة الزاوية © {new Date().getFullYear()}
            <a className="grey-text text-lighten-4 right" href="#headers">
              <i className="material-icons left">keyboard_arrow_up</i> الرجوع
              للاعلي
            </a>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Students;

import {
  GET_MEMBERS,
  GET_MEMBER,
  MEMBER_ERROR,
  SEARCH_MEMBERS,
  FILTER_MEMBERS,
  CLEAR_FILTER,
  CLEAR_MEMBER,
} from "../actions/types";

const initialState = {
  members: [],
  member: null,
  filtered: [],
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_MEMBERS:
      return {
        ...state,
        members: payload,
        loading: false,
      };
    case SEARCH_MEMBERS:
      return {
        ...state,
        members: payload,
        loading: false,
      };
    case FILTER_MEMBERS:
      return {
        ...state,
        filtered: state.members.filter((member) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return member.name.match(regex);
        }),
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null,
      };
    case CLEAR_MEMBER:
      return {
        ...state,
        member: null,
        loading: true,
      };
    case GET_MEMBER:
      return {
        ...state,
        member: payload,
        loading: false,
      };

    case MEMBER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}

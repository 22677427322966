import React, { useEffect, Fragment } from "react";
import Spinner from "../layout/Spinner";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSubject } from "../../actions/Subject.actions";
import Subjecti from "../subject/Subject.component";

const Subject = ({ match, getSubject, subjects: { subject, loading } }) => {
  useEffect(() => {
    getSubject(match.params.id);
  }, [loading]);
  return !subject ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container">
        <Subjecti subject={subject} />
      </div>
    </Fragment>
  );
};

Subject.propTypes = {
  getSubject: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  subjects: state.subjects
});

export default connect(mapStateToProps, { getSubject })(Subject);

import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { clearFilter, filterSubject } from "../../actions/Subject.actions";

const ContactFilter = ({
  filterSubject,
  clearFilter,
  subjects: { filtered },
}) => {
  useEffect(() => {
    if (filtered === null) {
      text.current.value = "";
    }
  });
  const text = useRef("");

  const onChange = (e) => {
    if (text.current.value !== "") {
      filterSubject(e.target.value);
    } else {
      clearFilter();
    }
  };

  return (
    <form>
      <input
        ref={text}
        type="text"
        placeholder="للبحث اكتب هنا ..."
        onChange={onChange}
      />
      <span className="helper-text" data-error="wrong" data-success="right">
        يمكنك البحث باسم المادة ،اسم استاذ المادة ، اسم الكلية او القسم
      </span>
    </form>
  );
};
ContactFilter.propTypes = {
  filterSubject: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  subjects: state.subjects,
});

export default connect(mapStateToProps, { filterSubject, clearFilter })(
  ContactFilter
);

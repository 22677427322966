import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { clearMember } from "../../actions/Member.actions";

const StudentItem = ({ clearMember, member: { _id, name, image } }) => {
  return (
    <div className="card text-center">
      <img
        src={`https://portal.zu.edu.ly${image}`}
        alt=""
        className="round-img"
        style={{ width: "60px" }}
      />
      <h6>{name}</h6>

      <div>
        <Link
          to={`/${_id}`}
          className="btn btn-dark btn-sm mt-2"
          onClick={() => clearMember()}
        >
          المزيد ...
        </Link>
      </div>
    </div>
  );
};

StudentItem.propTypes = {
  member: PropTypes.object.isRequired,
};

export default connect(null, { clearMember })(StudentItem);

import {
  GET_SUBJECTS,
  GET_SUBJECT,
  SUBJECT_ERROR,
  SEARCH_SUBJECTS,
  FILTER_SUBJECTS,
  CLEAR_FILTER,
  CLEAR_SUBJECT,
} from "../actions/types";

const initialState = {
  subjects: [],
  subject: null,
  filtered: [],
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SUBJECTS:
      return {
        ...state,
        subjects: payload,
        loading: false,
      };
    case SEARCH_SUBJECTS:
      return {
        ...state,
        subjects: payload,
        loading: false,
      };
    case FILTER_SUBJECTS:
      return {
        ...state,
        filtered: state.subjects.filter((subject) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            subject.subjectname.match(regex) ||
            (subject.subjectteacher && subject.subjectteacher.match(regex)) ||
            (subject.faculty && subject.faculty.match(regex)) ||
            (subject.department && subject.department.match(regex))
          );
        }),
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null,
      };
    case CLEAR_SUBJECT:
      return {
        ...state,
        subject: null,
        loading: true,
      };

    case GET_SUBJECT:
      return {
        ...state,
        subject: payload,
        loading: false,
      };

    case SUBJECT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}

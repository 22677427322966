import React, { Fragment, useEffect } from "react";

import Members from "./components/pages/members";
import Home from "./components/pages/Home";
import Member from "./components/pages/member";
import Subjects from "./components/pages/Subjects";
import Subject from "./components/pages/subject";
import Students from "./components/pages/Students";
import NotFound from "./components/pages/NotFound";
import NavBar from "./components/layout/Navbar";

import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Redux
import { Provider } from "react-redux";
import store from "./store";

import "materialize-css/dist/css/materialize.min.css";
import M from "materialize-css/dist/js/materialize.min.js";

const App = () => {
  useEffect(() => {
    // Init Materialize JS
    M.AutoInit();
  }, []);
  return (
    <Provider store={store}>
      <Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/subjects" component={Subjects} />
            <Route exact path="/members" component={Members} />
            <Route exact path="/students" component={Students} />
            <Route exact path="/:id" component={Member} />
            <Route exact path="/subject/:id" component={Subject} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Fragment>
    </Provider>
  );
};

export default App;

import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addStudent } from "../../actions/Students";
import M from "materialize-css/dist/js/materialize.min.js";
import { getFaculties, getDepartment } from "../../actions/faculty.action";
import Select from "react-select";
import "./style.css";

const AddStudentModal = ({
  addStudent,
  getFaculties,
  getDepartment,
  faculties: { faculies, faculy, loading },
}) => {
  useEffect(() => {
    var elems2 = document.querySelectorAll(".modal");
    M.Modal.init(elems2, {
      opacity: 0.7,
      inDuration: 300,
    });

    var elem = document.querySelectorAll("select");
    M.FormSelect.init(elem, {});
    getFaculties();
  }, [loading]);
  const [Student, setStudent] = useState({
    ids: 0,
    idd: 0,
    nationality: "",
    name: "",
    enName: "",
    address: "",
    phone: "",
    email: "",
    studyYear: "",
    faculty: "",
    department: "",
  });

  let options = [];

  faculy &&
    faculy.map((fac) =>
      fac.departments.map((department) =>
        options.push({
          value: department.departmentname,
          label: department.departmentname,
        })
      )
    );

  const onChangeFaculty = (e) => {
    setStudent({ ...Student, [e.target.name]: e.target.value });
    console.log(e.target.value);
    getDepartment(e.target.value);
  };

  const onChange = (e) => {
    setStudent({ ...Student, [e.target.name]: e.target.value });
  };
  const onChangeSelect = (selected) => {
    setStudent({ ...Student, department: selected.value });
  };

  const {
    ids,
    idd,
    name,
    nationality,
    enName,
    address,
    phone,
    email,
    studyYear,
    faculty,
    department,
  } = Student;

  const onSubmit = () => {
    if (
      name === "" ||
      phone === "" ||
      idd === 0 ||
      nationality === "" ||
      enName === "" ||
      address === "" ||
      studyYear === "" ||
      faculty === "" ||
      department === ""
    ) {
      M.toast({ html: "آرجو تعبئة كل الحقول ،جميع الحقول مطلوبة" });
    } else {
      addStudent({
        ids,
        idd,
        nationality,
        name,
        enName,
        address,
        phone,
        email,
        studyYear,
        faculty,
        department,
      });

      M.toast({ html: " تمت الاضافة  بنجاح ارجو عدم اعادة التعبئة مرة اخرى" });

      // Clear Fields
      setStudent({
        ids: 0,
        idd: 0,
        name: "",
        nationality: "",
        enName: "",
        address: "",
        phone: "",
        email: "",
        studyYear: "",
        faculty: "",
        department: "",
      });
    }
  };

  return (
    <section class="section section-login">
      <div class="container">
        <div></div>

        <div class="row">
          <div class="col s12   ">
            <h3 className="center alert-danger">
              تعليمات هامة عند تعبئة النموذج
            </h3>
            <ul
              style={{ color: "firebrick", fontSize: "18px" }}
              className="center"
            >
              <li>الرجاء تعبئة الاسم كما في شهادة الميلاد</li>
              <li>لا يسمح بتكرار تعبئة النموذج اكثر من مرة</li>
              <li>الرجاء التأكد من صحة البيانات قبل الارسال</li>
              <li>
                يسمح للطلبة الذين ليس لديهم شفرات مدار بتعبئة النموذج بشفرات
                ليبيانا
              </li>
              <li>يجب ان يكون رقم الهاتف مسجل باسم الطالب</li>
              <li>في حالة لم يصدر لطالب رقم قيد يدخل الرقم الوطني بدلا منه</li>
            </ul>
            <div class="card-panel center">
              <h4 className="center"> نموذج تسجيل الطلبة</h4>
              <br />
              <br />
              <div className="row ">
                <div className="input-field r ">
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={onChange}
                    className=""
                  />
                  <label htmlFor="firstName" className="active ">
                    الاسم رباعي باللغة العربية
                  </label>
                </div>
              </div>
              <div className="row ">
                <div className="input-field r ">
                  <input
                    type="text"
                    name="enName"
                    value={enName}
                    onChange={onChange}
                    className=""
                  />
                  <label htmlFor="firstName" className="active ">
                    الاسم رباعي باللغة الانجليزية
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="input-field ">
                  <input
                    type="number"
                    name="ids"
                    value={ids}
                    onChange={onChange}
                  />
                  <label htmlFor="firstName" className="active right">
                    رقم القيد
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="input-field ">
                  <input
                    type="number"
                    name="idd"
                    value={idd}
                    onChange={onChange}
                  />
                  <label htmlFor="firstName" className="active right">
                    الرقم الوطني
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="input-field ">
                  <input
                    type="text"
                    name="nationality"
                    value={nationality}
                    onChange={onChange}
                  />
                  <label htmlFor="firstName" className="active right">
                    جنسية الطالب
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="input-field" style={{ paddingBottom: "30px" }}>
                  <select
                    name="faculty"
                    value={faculty}
                    onChange={onChangeFaculty}
                  >
                    <option value="" disabled selected>
                      {"        "}
                      اختر الكلية
                    </option>
                    {faculies.map((fac) => (
                      <option key={fac._id} value={fac.facultyname}>
                        {"        "}
                        {fac.facultyname}{" "}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="input-field">
                  <select
                    name="studyYear"
                    value={studyYear}
                    onChange={onChange}
                  >
                    <option value="" disabled selected>
                      {"        "}
                      اختر المرحلة
                    </option>
                    <option value="السنة الاعداد">سنة الإعداد</option>
                    <option value="السنة الاولى">السنة الاولى</option>
                    <option value="السنة التانية">السنة التانية</option>
                    <option value="السنة الثالثة">السنة الثالثة</option>
                    <option value="السنة الرابعة">السنة الرابعة</option>
                    <option value="السنة الخامسة">السنة الخامسة</option>
                    <option value="الفصل الاول">الفصل الاول</option>
                    <option value="الفصل التاني">الفصل التاني</option>
                    <option value="الفصل الثالث">الفصل الثالث</option>
                    <option value="الفصل الرابع">الفصل الرابع</option>
                    <option value="الفصل الخامس">الفصل الخامس</option>
                    <option value="الفصل السادس">الفصل السادس</option>
                    <option value="الفصل السابع">الفصل السابع</option>
                    <option value="الفصل الثامن">الفصل الثامن</option>
                    <option value="الفصل التاسع">الفصل التاسع</option>
                    <option value="الفصل العاشر">الفصل العاشر</option>
                  </select>
                  <label>المرحلة الدراسية</label>
                </div>
              </div>
              <Select
                options={options}
                name="department"
                noOptionsMessage={() => "اختر الكلية من فضلك"}
                placeholder={<div>اختر القسم</div>}
                onChange={onChangeSelect}
              />

              <div className="row">
                <div className="input-field">
                  <input
                    type="text"
                    name="address"
                    value={address}
                    onChange={onChange}
                  />
                  <label htmlFor="firstName" className="active">
                    العنوان
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="input-field">
                  <input
                    type="text"
                    name="phone"
                    value={phone}
                    onChange={onChange}
                  />
                  <label htmlFor="firstName" className="active">
                    رقم الهاتف
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="input-field">
                  <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={onChange}
                  />
                  <label htmlFor="firstName" className="active">
                    البريد الالكتروني ان وجد
                  </label>
                </div>
              </div>

              <a
                onClick={onSubmit}
                className="modal-close waves-effect blue waves-light btn center"
              >
                تسجيل
              </a>
            </div>
          </div>
        </div>
      </div>
      <p style={{ color: "firebrick" }} className="center">
        للاتصال او التبليغ عن اي مشاكل
      </p>
      <p style={{ color: "firebrick" }} className="center">
        idc@zu.edu.ly الرجاء مراسلتنا على البريد الالكتروني التالي
      </p>
    </section>
  );
};

AddStudentModal.propTypes = {
  addStudent: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  faculties: state.faculties,
});

export default connect(mapStateToProps, {
  addStudent,
  getDepartment,
  getFaculties,
})(AddStudentModal);

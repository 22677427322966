import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_STUDENTS,
  GET_STUDENT,
  STUDENT_ERROR,
  DELETE_STUDENT,
  ADD_STUDENT,
  UPDATE_STUDENT,
  FILTER_STUDENT,
  CLEAR_FILTER,
} from "./types";

// Get Students
export const getStudents = () => async (dispatch) => {
  try {
    const res = await axios.get("https://portal.zu.edu.ly/api/students");

    dispatch({
      type: GET_STUDENTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// filter student
export const filterStudents = (text) => (dispatch) => {
  dispatch({ type: FILTER_STUDENT, payload: text });
};

//clear filter
export const clearFilter = () => (dispatch) => {
  dispatch({ type: CLEAR_FILTER });
};

// Get one Student
export const getStudent = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`https://portal.zu.edu.ly/api/students/${id}`);
    dispatch({
      type: GET_STUDENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add Students
export const addStudent = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };

  try {
    const res = await axios.post(
      "https://portal.zu.edu.ly/api/students",
      formData,
      config
    );
    dispatch({
      type: ADD_STUDENT,
      payload: res.data,
    });

    dispatch(setAlert("تمت الاضافة بنجاح", "success"));
  } catch (err) {
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Update Student
export const updateStudent = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.put(
      `https://portal.zu.edu.ly/api/students/${formData._id}`,
      formData,
      config
    );

    console.log(res.data);
    dispatch({
      type: UPDATE_STUDENT,
      payload: res.data,
    });

    dispatch(setAlert("تمت عملية التعديل بنجاح", "success"));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Delete Students
export const deleteStudent = (id) => async (dispatch) => {
  try {
    await axios.delete(`https://portal.zu.edu.ly/api/students/${id}`);

    dispatch({
      type: DELETE_STUDENT,
      payload: id,
    });

    dispatch(setAlert("تم الحذف", "success"));
  } catch (err) {
    dispatch({
      type: STUDENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

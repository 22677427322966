import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import M from "materialize-css/dist/js/materialize.min.js";

const Navbar = ({ icon, title }) => {
  useEffect(() => {
    var elems = document.querySelectorAll(".sidenav");
    M.Sidenav.init(elems, {});
  }, []);

  return (
    <Fragment>
      <nav className="bg-primary navbar" style={{ direction: "rtl" }}>
        <p>
          <i className={icon} /> {title}
        </p>
        <ul
          style={{ paddingTop: "0px" }}
          id="nav-mobile"
          class=" hide-on-med-and-down"
        >
          <li>
            <Link to="/" style={{ paddingTop: "0px" }}>
              نبذة عن التعليم الالكتروني
            </Link>
          </li>
          <li>
            <Link to="/subjects" style={{ paddingTop: "0px" }}>
              المواد و المناهج الدراسية
            </Link>
          </li>
          <li>
            <Link to="/members" style={{ paddingTop: "0px" }}>
              اعضاء هيئة التدريس
            </Link>
          </li>
          <li>
            <Link to="/students" style={{ paddingTop: "0px" }}>
              {" "}
              الطلبة
            </Link>
          </li>
        </ul>
        <a href="#" data-target="slide-out" class="sidenav-trigger">
          <i class="material-icons">menu</i>
        </a>
      </nav>

      <ul id="slide-out" class="sidenav" style={{ marginTop: "-10px" }}>
        <li>
          <Link to="/" style={{ paddingTop: "0px" }}>
            نبذة عن التعليم الالكتروني
          </Link>
        </li>
        <li>
          <Link to="/subjects" style={{ paddingTop: "0px" }}>
            المواد و المناهج الدراسية
          </Link>
        </li>
        <li>
          <Link to="/members" style={{ paddingTop: "0px" }}>
            اعضاء هيئة التدريس
          </Link>
        </li>
        <li>
          <Link to="/students" style={{ paddingTop: "0px" }}>
            {" "}
            الطلبة
          </Link>
        </li>
      </ul>
    </Fragment>
  );
};

Navbar.defaultProps = {
  title: "التعليم الالكتروني | جامعة الزاوية",
  icon: "fas fa-chalkboard-teacher",
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default Navbar;

import React, { useEffect, Fragment } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import { getSubjects } from "../../actions/Subject.actions";
import { getMembers } from "../../actions/Member.actions";
import Navbar from "../layout/Navbar";

const Home = ({ getSubjects, getMembers, subjects: { subjects, loading } }) => {
  useEffect(() => {
    getSubjects();
    getMembers();
    M.AutoInit();
  }, []);

  let newsubjects = [];
  subjects &&
    subjects.map((subject) =>
      subject.files.length > 0 ? newsubjects.push(subject) : null
    );
  return (
    <div>
      <Navbar
        title="التعليم الالكتروني | جامعة الزاوية"
        icon="fas fa-chalkboard-teacher"
      />
      <header id="headers" className="main-header" style={{ direction: "rtl" }}>
        <div className="primary-overlay">
          <div className="showcase container">
            <div className="row">
              <div className="col s12 main-text">
                <br />
                <br />

                <h3> التعليم الالكتروني</h3>
                <p className="flow-text">
                  يطلق مفهوم التعليم الإلكتروني على العملية المتكاملة التي يتمُّ
                  فيها استخدام التكنولوجيا الحديثة في الوصول إلى المناهج
                  التعليمية والحصول على المعلومات، ويرتكز التعليم الإلكترونيّ
                  بشكل رئيس على شبكة الإنترنت و الشبكات المحلية وما تحتويه من
                  مواقع إلكترونية متخصصة بالجانب التعليمي، ويدخل في مفهوم
                  التعليم الإلكتروني أيضًا كافة الدورات التدريبية أو البرامج
                  التدريبية التي يتمُّ طرحها على الشبكة ليحصل المستفيد منها على
                  كافة الدروس ذات العلاقة بالدورة أو البرنامج التدريبية عن بعد،
                  وقد بدأت ملامح التعليم الإلكتروني بالظهور شيئًا فشيئًا مع
                  اختراع جهاز الحاسوب وظهور شبكة الإنترنت التي مكَّنت ملايين
                  المستخدمين من التواصل مع بعضهم البعض في مختلف الشؤون بما في
                  ذلك الشؤون التعليمية.
                </p>
                <br />

                <a href="#popular" className="white-text">
                  <i className="material-icons medium scroll-icon ">
                    arrow_drop_down_circle
                  </i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section
        className="section section-popular scrollspy"
        style={{ direction: "rtl" }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col s12 m8">
              <p style={{ fontSize: "1.2rem", lineHeight: "3rem" }}>
                <li>
                  المشاكل الامنية التي قد تكون حائل لوصول الطلبة و الاساتذة
                  للكليات بالجامعة
                </li>
                <li>
                  {" "}
                  الانفجار المعرفي وتزايد المعلومات: حيث أصبحت المؤسسات
                  التعليمية عاجزة عن مسايرته، الأمر الذي جعل البحث عن بدائل أمر
                  في غاية الأهمية.{" "}
                </li>

                <li>
                  المشاكل الاقتصادية والاجتماعية و خاصه للعنصر النسائي، فأصبحت
                  المؤسسات التقليدية عاجزة عن تلبية احتياجات التعليم لمجموع
                  الطلاب.{" "}
                </li>
                <li>
                  {" "}
                  الأخذ بديمقراطية التعليم: حيث إنّ الديمقراطية في التعليم أصبحت
                  من مقومات الأمن القومي، ولا يمكن تحقيقها في ظل التعليم
                  التقليدي، مما يؤكد الحاجة الملحة للتعليم الإلكتروني.{" "}
                </li>
                <li>
                  القصور في توفير كوادر تعليمية مؤهلة: يعدّ تطوير الكادر
                  التعليمي أمرًا في غاية الأهمية، وهذا هدف من أهداف التعليم
                  الإلكتروني، ويساعد التعليم الإلكتروني في إحداث طفرة من حيث
                  إعداد الكوادر التعليمية.
                </li>
              </p>
            </div>
            <div className="col s12 m4">
              <br />
              <hr className="teal darken-1 " style={{ padding: "1px" }} />
              <blockquote
                style={{ fontSize: "1.4rem" }}
                className="text-center"
              >
                لماذا التوجه للتعليم الالكتروني بالجامعة
              </blockquote>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col s12  conainer">
                <ul className="collapsible">
                  <li>
                    <div className="collapsible-header">
                      <i className="material-icons">email</i> اهمية التعليم
                      الالكتروني
                    </div>
                    <div className="collapsible-body left">
                      <p style={{ fontSize: "1rem", lineHeight: "2rem" }}>
                        توفير المزيد من المرونة في طرح المعلومات دون وجود وقت
                        محدد أو مكان محدد لإعطاء الدروس والمناهج التعليمية،
                        والقدرة على التعليم الجزئي إلى جانب العمل، وزيادة اعتماد
                        المتعلم على نفسه بشكل أكبر بصفته محور العملية التعليمية،
                        فضلًا عن إتاحة الفرصة أمام العديد من شرائح المجتمع التي
                        تحول حواجز التعليم المادي إلى دخولها في العملية
                        التعليمية، أمَّا أبرز عيوب التعليم الإلكتروني فتتمثل في
                        تفاوت درجة أمان البرامج أو المواقع التعليمية، بالإضافة
                        إلى اقتصار العديد من اختبارات البرامج التعليمية على
                        الأسئلة الموضوعية
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="collapsible-header">
                      <i className="material-icons">place</i>اهداف التعليم
                      الالكتروني
                    </div>
                    <div className="collapsible-body">
                      <p style={{ fontSize: "1rem", lineHeight: "2rem" }}>
                        <li>
                          {" "}
                          خلق بيئة تفاعلية من خلال الأجهزة والتقنيات الإلكترونية
                          الجديدة، ووجود تنوع في مصادر المعلومات والخبرة.
                        </li>
                        <li>
                          {" "}
                          دعم وتحسين عملية التواصل بين الطلاب والمعلمين
                          والمساعدين من المناقشات الهادفة بالاستعانة بقنوات
                          الاتصال الإلكترونية. عدم ضرورة الحضور في المكان نفسه
                          من خلال التعلم عن بعد، وهذا من أهم أهداف التعليم
                          الإلكتروني.
                        </li>
                        <li>
                          إكساب المعلمين المهارات التقنية لاستخدام الأجهزة
                          الحديثة والتقنيات الإلكترونية، التي تساعد في عملية
                          التعلم الجماعي والتعلم الذاتي.{" "}
                        </li>
                        <li>
                          {" "}
                          إكساب الطلاب المهارات التقنية اللازمة لاستخدام وسائل
                          الاتصال الإلكترونية اللازمة في عملية التعليم
                          الإلكتروني. تطوير دور المعلم في بيئة العملية التعليمية
                          حتى يواكب التطور العلمي والتكنولوجيا المتلاحقة والتي
                          تستمر بالتغير.
                        </li>
                        <li>
                          توسيع دائرة اتصالات الطلاب من خلال شبكات الاتصالات
                          العالمية والمحلية وعدم الاعتماد على المعلم كمصدر وحيد
                          للمعلومات والمعرفة. تقديم التعليم بما يتناسب مع الفئة
                          العمرية التي يقدم لها، ومراعاة الفروق الفردية بين
                          الطلاب.
                        </li>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="collapsible-header">
                      <i className="material-icons">whatshot</i> دور المعلم في
                      بيئة التعليم الإلكتروني
                    </div>
                    <div className="collapsible-body">
                      <p style={{ fontSize: "1rem", lineHeight: "2rem" }}>
                        أن للمعلم دورًا مهمًّا في عملية التعليم الإلكتروني،
                        فالمعلم عصب العملية التعليمية، ولا يمكن الاستغناء عنه
                        ومن أدوار المعلم في البيئة التعليمية الإلكترونية كالآتي
                        <li>
                          تشخيص احتياجات الطلاب وخصائص الطلاب، ومن ثم تحديد
                          الاستراتيجيات الملائمة لكل من المحتوى والطالب.
                        </li>
                        <li>
                          تحديد الأنشطة الملائمة لكلّ استراتيجية، وتحديد أدوات
                          الإنترنت الملائمة لتطبيق تلك الاستراتيجيات والأنشطة.
                        </li>
                        <li>
                          إعداد نماذج تقييم ذاتي للمحتوى، ومن ثم القيام بعمليات
                          التغذية الراجعة للطلاب، وتقييم نتائج تطبيق
                          الاستراتيجيات.{" "}
                        </li>
                        <li>
                          الإشراف على إدارة المواقف التعليمية الإلكترونية،
                          والإشراف والمشاركة في عمليات التفاعل الفردي والجماعي.
                        </li>
                        <li>
                          {" "}
                          القيام بعمليات البرمجة الفنية لأنشطة وأدوات النظام
                          التعليمي، ومحاولة حل أي عطل فني خلال العملية
                          التعليمية.
                        </li>
                        <li>
                          {" "}
                          المساهمة في تشكيل مجموعات للتعلم التعاوني في ضوء
                          التعليم الإلكتروني، لتشجيع الطلاب نحو التعليم
                          الإلكتروني.
                        </li>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* /// courses */}
      <section
        id="popular"
        className="section section-popular grey lighten-3 scrollspy"
      >
        {loading ? (
          <Spinner />
        ) : (
          <div className="row " style={{ direction: "rtl" }}>
            <h4 className="center">
              احدث{" "}
              <span className=" lighten-1 blue-text">الدورات و المناهح </span>
            </h4>
            {newsubjects.slice(0, 4).map((subject) => (
              <Fragment>
                <div className="col s12 m3">
                  <div
                    className="card hoverable"
                    style={{ maxHeight: "450px" }}
                  >
                    <div className="card-image">
                      <img
                        style={{ maxHeight: "200px" }}
                        src={`https://portal.zu.edu.ly${subject.image}`}
                        alt={subject.subjectname}
                      />
                    </div>
                    <div className="card-content ">
                      <span className="card-title">{subject.subjectname}</span>
                      <small>{subject.subjectteacher}</small>
                      <p className="truncate">{subject.about}</p>
                    </div>
                    <div className="card-action text-center">
                      <Link to={`/subject/${subject._id}`}>التفاصيل</Link>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        )}

        <div className="row">
          <div className="col s12 center">
            <Link className="btn btn-large grey darken-3" to="/subjects">
              {" "}
              للمزيد من الدورات و المناهج
            </Link>
          </div>
        </div>
      </section>
      {/* <!-- Footer --> */}
      <footer className="page-footer blue darken-1 ">
        <div className="footer-copyright blue lighten-1">
          <div className="container">
            جامعة الزاوية © {new Date().getFullYear()}
            <a className="grey-text text-lighten-4 right" href="#headers">
              <i className="material-icons left">keyboard_arrow_up</i> الرجوع
              للاعلي
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

Home.propTypes = {
  getSubjects: PropTypes.func.isRequired,
  getMembers: PropTypes.func.isRequired,
  subjects: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  subjects: state.subjects,
});

export default connect(mapStateToProps, { getSubjects, getMembers })(Home);

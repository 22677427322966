import React, { Fragment } from "react";
import Subjects from "../subject/Subjects.component";
import Navbar from "../layout/Navbar";

const Subject = () => (
  <Fragment>
    <Navbar
      title="المواد المضافة من قبل اعضاء هيئة التدريس  | جامعة الزاوية  "
      icon="fas fa-book-reader"
    />
    <div className="container">
      <Subjects />
    </div>
  </Fragment>
);

export default Subject;

import React, { useContext, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { filterMembers, clearFilter } from "../../actions/Member.actions";

const ContactFilter = ({
  filterMembers,
  clearFilter,
  members: { filtered }
}) => {
  useEffect(() => {
    if (filtered === null) {
      text.current.value = "";
    }
  });
  const text = useRef("");

  const onChange = e => {
    if (text.current.value !== "") {
      filterMembers(e.target.value);
    } else {
      clearFilter();
    }
  };

  return (
    <form>
      <input
        ref={text}
        type="text"
        placeholder="بحث باسم الطالب..."
        onChange={onChange}
      />
    </form>
  );
};
ContactFilter.propTypes = {
  filterMembers: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  members: state.members
});

export default connect(mapStateToProps, { filterMembers, clearFilter })(
  ContactFilter
);

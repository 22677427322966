import React, { Fragment } from "react";
import Members from "../members/Members.component";
import Navbar from "../layout/Navbar";

const Home = () => (
  <Fragment>
    <Navbar
      title="أعضاء هيئة التدريس بالبوابة الالكتروني  | قسم الموقع الالكتروني"
      icon="fas fa-users"
    />
    <div className="container">
      <Members />
    </div>
  </Fragment>
);

export default Home;

import axios from "axios";
import {
  GET_MEMBERS,
  GET_MEMBER,
  MEMBER_ERROR,
  SEARCH_MEMBERS,
  FILTER_MEMBERS,
  CLEAR_FILTER,
  CLEAR_MEMBER,
} from "./types";

// Get Students
export const getMembers = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };
    const res = await axios.get(
      "https://portal.zu.edu.ly/api/facultymembers/all",
      config
    );

    dispatch({
      type: GET_MEMBERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: MEMBER_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const searchUsers = (text) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };
    const res = await axios.get(
      `https://portal.zu.edu.ly/api/facultymembers?text=${text}`,
      config
    );

    console.log(res);

    dispatch({
      type: SEARCH_MEMBERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

// filter student
export const filterMembers = (text) => (dispatch) => {
  dispatch({ type: FILTER_MEMBERS, payload: text });
};

//clear filter
export const clearFilter = () => (dispatch) => {
  dispatch({ type: CLEAR_FILTER });
};

//clear Member
export const clearMember = () => (dispatch) => {
  dispatch({ type: CLEAR_MEMBER });
};

// Get one Student
export const getMember = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `https://portal.zu.edu.ly/api/facultymembers/${id}`
    );
    dispatch({
      type: GET_MEMBER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: MEMBER_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";

import { getMembers } from "../../actions/Member.actions";

import Filter from "./MembersFilter.components";
import MembersItem from "./MembersItem.components";

const Members = ({ getMembers, members: { members, filtered, loading } }) => {
  useEffect(() => {
    getMembers();
  }, [loading]);

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div style={{ direction: "rtl" }}>
        <Filter />
      </div>
      <div style={userStyle}>
        {filtered !== null && filtered.length !== 0
          ? filtered.map(member => (
              <MembersItem key={member._id} member={member} />
            ))
          : members.map(member => (
              <MembersItem key={member._id} member={member} />
            ))}
      </div>
    </Fragment>
  );
};

Members.propTypes = {
  getMembers: PropTypes.func.isRequired,
  members: PropTypes.object.isRequired
};
const userStyle = {
  direction: "rtl",
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridGap: "1rem"
};
const mapStateToProps = state => ({
  members: state.members
});

export default connect(mapStateToProps, { getMembers })(Members);

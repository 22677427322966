import React, { Fragment, useState, useEffect } from "react";
import Spinner from "../layout/Spinner";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import M from "materialize-css/dist/js/materialize.min.js";

const Member = ({ subject }) => {
  useEffect(() => {
    var elems2 = document.querySelectorAll(".modal");
    M.Modal.init(elems2, {
      opacity: 0.7,
      inDuration: 300
    });
  }, []);
  const [videourl, setvideo] = useState({ uri: "", videoname: "" });
  const videourlchange = (urlv, name) => {
    console.log("Url : " + urlv);
    setvideo({ uri: urlv, videoname: name });
    console.log(videourl);
  };

  return !subject ? (
    <Spinner />
  ) : (
    <Fragment>
      <Link to="/subjects" className="btn btn-light">
        الرجوع للبحث
      </Link>

      <Fragment>
        <div className="col s12 " style={{ direction: "rtl" }}>
          <div className="card horizontal">
            <div className="card-image">
              <img
                src={`https://portal.zu.edu.ly${subject.image}`}
                alt="subjects"
              />
            </div>
            <div className="card-stacked">
              <div className="card-content">
                <h5>
                  {" "}
                  المادة :<u> {subject.subjectname}</u>
                </h5>
                الاستاذ : <u>{subject.subjectteacher}</u>
                <p>
                  عن المادة : <u>{subject.about}</u>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card ">
          <h4 className="text-center my-2">
            {" "}
            <u>المادة العلمية</u>
          </h4>

          <ul className="collection" style={{ direction: "rtl" }}>
            {subject.files.map(file => (
              <li className="collection-item avatar ">
                {file.category === "video" ? (
                  <Fragment>
                    <a
                      onClick={videourlchange.bind(
                        null,
                        file.link,
                        file.filename
                      )}
                      className="  modal-trigger"
                      href="#modal1"
                    >
                      <i className="material-icons circle red ">play_arrow</i>
                    </a>

                    <span className="title">{file.filename}</span>
                    <p>{file.discription}</p>
                  </Fragment>
                ) : (
                  <Fragment>
                    <a
                      href={`https://portal.zu.edu.ly${file.file}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {"  "}
                      <i className="material-icons circle">insert_drive_file</i>
                    </a>

                    <span className="title">{file.filename}</span>
                    <p>{file.discription}</p>
                  </Fragment>
                )}
              </li>
            ))}
          </ul>

          <div id="modal1" className="modal" style={{ direction: "rtl" }}>
            <div className="modal-content">
              <h4 className="text-right">{videourl.videoname}</h4>
              <ReactPlayer url={videourl.uri} controls={true} />
            </div>

            <div className="modal-footer">
              <a
                href="#!"
                className="modal-close waves-effect waves-green btn-flat"
              >
                اغلاق
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
};

export default Member;
